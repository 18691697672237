// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
}

.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select:not(.is-multiple),
.select:not(.is-multiple) select,
.textarea {
  height: 2.572em;
}

.button {
  text-transform: uppercase;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.navbar {
  border-radius: $radius;

  .navbar-item,
  .navbar-link {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;

    &.is-active {
      background-color: darken($grey-dark, 5);

      @include touch {
        background-color: rgba($grey-dark, 0.25);
      }
    }
  }

  @include desktop {
    .navbar-dropdown .navbar-item {
      color: $text;
    }
  }

  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  &:not([class*="is-"]) .navbar-burger span {
    background-color: $white-ter;
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include touch {
        .navbar-item,
        .navbar-link {
          color: rgba($color-invert, 0.7);
          &.is-active {
            color: $color-invert;
          }
        }
      }
    }
  }

  &.is-transparent {
    background-color: transparent;
  }
}

.hero {
  // Colors
  .navbar {
    background-color: $grey-dark;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}

.pagination-link,
.pagination-next,
.pagination-previous {
  color: $primary;
  background-color: $pagination-background-color;
}
